import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import connectDynamicWidgets from "instantsearch.js/es/connectors/dynamic-widgets/connectDynamicWidgets.js";
import { useConnector } from "../hooks/useConnector.js";
export function useDynamicWidgets(props, additionalWidgetProperties) {
  return useConnector(connectDynamicWidgets, _objectSpread(_objectSpread({}, props), {}, {
    // We don't rely on InstantSearch.js for rendering widgets because React
    // directly manipulates the children.
    widgets: []
  }), additionalWidgetProperties);
}